'use client'

import {
  RiCheckLine,
  RiCloseFill,
  RiDeleteBinLine,
  RiEdit2Fill,
  RiFileWordFill,
  RiMoreFill,
  RiShareLine,
} from '@remixicon/react'
import { Button, Dropdown, Input, message, Modal } from 'antd'
import { ItemType } from 'antd/es/menu/interface'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useAuth from '@/hooks/useAuth'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { DrawerContext } from '@/context/conversationDrawer/drawer.context'
import { cn } from '@/utils/clsx'

import { IAxiosError } from '@/types/api'
import { Conversation } from '@/types/chatbot'

interface ConversationsProps {
  conversations: Conversation[]
  mutateConversations: () => void
  setConversationsSize: React.Dispatch<React.SetStateAction<number>>
  noMoreData?: boolean
  onClose?: () => void
  className?: string
}

const Conversations: React.FC<ConversationsProps> = ({
  conversations,
  mutateConversations,
  setConversationsSize,
  noMoreData,
  onClose,
  className,
}) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { selectedConversation, setSelectedConversation } =
    useContext(DrawerContext)
  const [open, setOpen] = useState(false)
  const [chatIdToDelete, setChatIdToDelete] = useState<string | undefined>()
  const [chatInEdit, setChatInEdit] = useState<string | undefined>()
  const [newTitle, setNewTitle] = useState<string | undefined>()

  const items = (id: string): ItemType[] | undefined => [
    {
      label: (
        <div
          className='mt-px flex gap-4 p-0 text-center text-on-surface dark:text-dark-on-surface'
          onClick={(event) => {
            event.stopPropagation()
            exportToDocs(id)
          }}
        >
          <RiFileWordFill size={18} />
          <span>{t('google-docs-export')}</span>
        </div>
      ),
      key: '1',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <div
          className='mt-px flex gap-4 p-0 text-on-surface dark:text-dark-on-surface'
          onClick={(event) => {
            event.stopPropagation()
            prepareCopyUrl(id)
          }}
        >
          <RiShareLine size={18} />
          <span>{t('conversations-copy')}</span>
        </div>
      ),
      key: '2',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <div onClick={(event) => event.stopPropagation()}>
          <div
            className='mt-px flex gap-4 p-0 text-on-surface dark:text-dark-on-surface'
            onClick={(event) => {
              event.stopPropagation()
              setOpen(true)
              setChatIdToDelete(id)
            }}
          >
            <RiDeleteBinLine size={18} />
            <span>{t('conversations-delete-title')}</span>
          </div>
        </div>
      ),
      key: '3',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <div
          className='mt-px flex gap-4 p-0 text-on-surface dark:text-dark-on-surface'
          onClick={(event) => {
            event.stopPropagation()
            setChatInEdit(id)
          }}
        >
          <RiEdit2Fill size={18} />
          <span>{t('conversations-rename')}</span>
        </div>
      ),
      key: '4',
    },
  ]

  const handleDelete = async () => {
    const config = {
      method: 'delete',
      ...configHeader,
    }

    try {
      await axios(`${API_URL}/conversations/${chatIdToDelete}`, config)
      message.success(t('conversation-deleted-success'))
      await mutateConversations()

      if (selectedConversation === chatIdToDelete) {
        setSelectedConversation(undefined)
        onClose && onClose()
      }
    } catch (error) {
      message.error(
        (error as IAxiosError).response?.data?.message ??
          t('toast-unknown-error')
      )
      console.error(error)
    }
    setOpen(false)
    setChatIdToDelete(undefined)
  }

  const prepareCopyUrl = (id: string) => {
    navigator.clipboard.writeText(`${window.location.origin}/copy-chat/${id}`)
    message.success({ content: t('toast-chat-copy-success') })
  }

  const exportToDocs = async (id: string) => {
    const config = {
      method: 'post',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({
        conversationId: id as string,
        user: user?.email,
      }),
    }
    message.loading(t('toast-loading'))

    axios(`${API_URL}/google-doc`, config)
      .then(async (res) => {
        if (res.status > 399) throw Error(res.statusText)
        message.success(t('toast-google-docs-success'))
        const url = res.data.url
        window.open(url, '_blank')
      })
      .catch((error) => {
        message.error(t('toast-google-docs-failed'))
        console.error(error)
      })
  }

  const handleUpdateTitle = async () => {
    if (newTitle === undefined || newTitle === '') {
      message.error(t('conversations-rename-empty'))
    }

    const config = {
      method: 'put',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({
        title: newTitle,
      }),
    }

    try {
      await axios(`${API_URL}/conversations/${chatInEdit}`, config)
      message.success(t('conversations-rename-success'))
      await mutateConversations()
      setChatInEdit(undefined)
      setNewTitle(undefined)
    } catch (error) {
      message.error(
        (error as IAxiosError).response?.data?.message ??
          t('toast-unknown-error')
      )
      console.error(error)
    }
  }

  if (conversations === undefined || conversations.length === 0) {
    return (
      <div
        className={cn(
          'mt-5 overflow-hidden text-ellipsis text-center text-on-surface dark:text-dark-on-surface',
          className
        )}
      >
        {t('conversations-no-stored')}
      </div>
    )
  }

  return (
    <div
      className={cn(
        'hide-scrollbar my-1 flex h-[93%] flex-col gap-1 overflow-y-auto',
        className
      )}
    >
      {conversations?.map(({ id, title, question, timestamp }) => (
        <div
          key={id}
          className={`flex cursor-pointer justify-between rounded-md p-3 hover:bg-primary/20 hover:dark:bg-dark-primary/20 
            ${selectedConversation === id ? 'bg-primary/10 dark:bg-dark-primary/10' : ''}`}
          onClick={() => {
            setSelectedConversation(id)
            onClose && onClose()
          }}
        >
          <div className='w-[90%] pr-4 text-justify'>
            <div className='truncate text-on-surface dark:text-dark-on-surface'>
              {chatInEdit === id ? (
                <div
                  className='flex gap-2'
                  onClick={(event) => event.preventDefault()}
                >
                  <Input
                    value={newTitle}
                    defaultValue={title ?? question}
                    onPressEnter={handleUpdateTitle}
                    autoFocus
                    className='w-full'
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                  <RiCheckLine
                    onClick={(event) => {
                      event.stopPropagation()
                      handleUpdateTitle()
                    }}
                    className='size-6 cursor-pointer self-center hover:opacity-40'
                  />
                  <RiCloseFill
                    onClick={(event) => {
                      event.stopPropagation()
                      setChatInEdit(undefined)
                      setNewTitle(undefined)
                    }}
                    className='size-6 cursor-pointer self-center hover:opacity-40'
                  />
                </div>
              ) : (
                <>{title ?? question}</>
              )}
            </div>
            <div>
              <span
                className='text-xs text-on-surface/50 dark:text-dark-on-surface/50'
                style={{ fontSize: '0.6rem', lineHeight: '0.75rem' }}
              >
                {dayjs(+timestamp).format('DD-MM-YYYY HH:mm')}
              </span>
            </div>
          </div>
          <Dropdown menu={{ items: items(id) }}>
            <RiMoreFill
              onClick={(event) => event.stopPropagation()}
              className='size-6 cursor-pointer self-center hover:opacity-40'
            />
          </Dropdown>
        </div>
      ))}
      {!noMoreData && (
        <Button onClick={() => setConversationsSize((prev) => prev + 1)}>
          {t('conversations-load-more')}
        </Button>
      )}
      <Modal
        title={t('conversations-delete-title')}
        open={open}
        onOk={handleDelete}
        onCancel={() => {
          setOpen(false)
          setChatIdToDelete(undefined)
        }}
        okType='danger'
        okText={t('users-delete-confirm')}
        cancelText={t('users-delete-deny')}
      >
        <p>{t('conversations-delete-desc')}</p>
      </Modal>
    </div>
  )
}

export default Conversations
